import { uniq } from 'lodash-es';

import { OtpProvider } from '@bp/shared/domains/jwt-session';
import {
	Control, Default, FieldControlType, Mapper, MetadataEntity, Property, lowerCase,
	METADATA_OBJECT_VALIDATOR, IValidatableMetadataObject, IMetadataObjectValidatorFunc, DTO, NotSensitive
} from '@bp/shared/models/metadata';
import { IValidationErrors, Validators } from '@bp/shared/features/validation/models';

import { MemberRole } from './member-role.enum';
import { memberOtpProviderMetadata } from './member';

export class MemberInvitation extends MetadataEntity {

	@Control(FieldControlType.email, {
		required: true,
		validator: Validators.email(),
		controlValueTransformer: email => email && lowerCase(email),
	})
	@NotSensitive()
	email!: string;

	@Mapper(MemberRole)
	@Control(FieldControlType.select, {
		list: MemberRole.getList(),
		required: true,
	})
	role!: MemberRole;

	@Property(memberOtpProviderMetadata)
	otpProvider!: OtpProvider;

}

export class MembersInvitationsRequest extends MetadataEntity implements IValidatableMetadataObject {

	@Mapper(MemberInvitation)
	@Default([])
	invites!: MemberInvitation[];

	constructor(dto?: DTO<MembersInvitationsRequest>) {
		super(dto);
	}

	[METADATA_OBJECT_VALIDATOR]: IMetadataObjectValidatorFunc = () => this.__assertInvitationEmailsAreUnique();

	private __assertInvitationEmailsAreUnique(): Record<string, IValidationErrors> | null {
		const invitationEmails = this.invites.map(invitation => invitation.email.toLowerCase());
		const uniqueInvitationEmails = uniq(invitationEmails);

		return invitationEmails.length === uniqueInvitationEmails.length
			? null
			: { model: { invites: 'Emails used in invitations must be unique' } };
	}

}
